<template>
    <header class="header-mob  open container">
        <div class="overlay has-fade"></div>
        <nav id="header-nav" class="flex container flex-j-c-sb flex-a-i-c fl-x fl-j-sb fl-a-c">

            <div class="logo-container mr-3">
                <div class="logo">
                    <router-link to="/">
                        <img src="../assets/img/logo.png" class="logo-img-sm img-fluid py-2" alt="">
                    </router-link>
                </div>
            </div>
            <a href="#" class="header__toggle" @click.prevent="showMobileNav = !showMobileNav">
                <!-- <img src="images/icon-menu.svg" alt=""> -->
                <!--                        <span></span>-->
                <!--                        <span></span>-->
                <!--                        <span></span>-->
                <i class="fa fa-bars fa-2x color-primary color-red"></i>
            </a>
        </nav>
        <transition name="fade">
            <div class="header__menu fl-y p-2 pt-5" v-if="showMobileNav">
                <div v-for="(m,i) in menu" :key="i">
                    <div v-if="m.items" class="text-decoration-none fs-2 font-weight-semibold pos-r"
                         @click="openSubmenus(m.itemsRef)"
                         :class="{'active':activeMenu === m.itemsRef, 'in-active':activeMenu !== m.itemsRef}">
                        <i class="fa fa-chevron-down pos-a right-0"></i>
                        {{
                            m.text
                        }}
                    </div>
                    <div class="fl-x fl-j-s">
                        <router-link :to="m.link" @click.native="showMobileNav = !showMobileNav">
                            <btn design="basic-b" v-if="m.type === 'button'"
                                 class="nav-btn-cont round-nav-btn-r border-r-0 mt-3 px-5">{{ m.text }}
                            </btn>
                        </router-link>

                    </div>
                    <transition name="fade">

                        <ul v-if="m.items" :ref="m.itemsRef" class="list-unstyled pl-3 bg-1 p-2">
                            <li v-for="(item,i) in m.items" :key="i" class="py-2 fs-1 no-select">
                                <a :href="item.link" class="text-decoration-none no-select link-hover d-block"
                                   @click="showMobileNav = !showMobileNav">{{ item.text }}</a>
                            </li>
                        </ul>
                    </transition>
                </div>

            </div>
        </transition>

    </header>
</template>

<script>
import getMenu from '../data/menu';

export default {
    name: 'CustomMobileNavbar',
    data () {
        return {
            menu: getMenu(),
            showMobileNav: false,
            activeMenu: ''
        };
    },
    methods: {
        openSubmenus (refStr) {
            // this.activeMenu = refStr;
            if (this.activeMenu === refStr) {
                this.activeMenu = '';
            } else {
                this.activeMenu = refStr;
            }
            // alert('abc' + this.activeMenu);
            // alert(refStr);
            // this.menu.forEach(item => {
            //     // this.$refs[item.itemsRef].hide();
            //     // this.$refs[item.itemsRef][0].hidden = true;
            //     if (item.itemsRef !== refStr) {
            //         // this.$refs[item.itemsRef][0].style.visibility = 'hidden';
            //         this.$refs[item.itemsRef][0].classList.add('d-none');
            //         // this.$refs[item.itemsRef][0].style.height = 0;
            //
            //         // this.$refs[item.itemsRef][0].style.display = 'none';
            //     }
            // });
            // // this.$refs[refStr][0].hidden = false;
            // // this.$refs[refStr][0].style.visibility = 'visible !important';
            // this.$refs[refStr][0].classList.remove('d-none');
            // // this.$refs[refStr][0].style.height = 'auto !important';
            //
            // // this.$refs[refStr][0].style.display = 'block';
            //
            // console.log('menu items', this.$refs[refStr][0].style.display);
        }
    }
};
</script>

<style scoped lang="scss">

.header__menu {
    //height: 100%;
    min-height: 100vh;
}

.show-submenus {
    visibility: visible;
    height: auto;
}

.hide-submenus {
    visibility: hidden;
    height: 0;
}

.active {
    color: var(--color-primary);

    ~ ul {
        visibility: visible;
        height: auto;
    }
}

.in-active {
    ~ ul {
        visibility: hidden;
        height: 0;
    }
}

.right-0 {
    right: 0;
}

.color-red {
    color: var(--color-primary) !important;
}

.link-hover {
    &:hover,&:focus {
        color: var(--color-primary) !important;
        background: white !important;
        outline: none !important;
    }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>
