<template>
    <nav :class="className">
        <div class="logo-container">
            <div class="logo" @click="$emit('logo-clicked')">
                <slot name="logo">
                    {{ logoText }}
                </slot>
            </div>

            <div class="mobile-icons">
                <label class="burger-menu" @click="showMenu=!showMenu">
                    <span class="fa fa-bars"></span>
                </label>
            </div>
        </div>

        <ul class="nav-center-list" :class="{'show': showMenu, 'fl-gr-1': hasSeparator}">
            <lego-nav-item @link-event="bubble" @itemClicked="showMenu=false" v-bind="item"
                           :dropdownRound="dropdownRound" :shadow="shadow" v-for="(item, index) in items.slice(0, 3)"
                           :key="index"/>
        </ul>
        <ul class="nav-end-list" :class="{'show': showMenu, 'fl-gr-1': hasSeparator}">
            <lego-nav-item @link-event="bubble" @itemClicked="showMenu=false" v-bind="item"
                           :dropdownRound="dropdownRound" :shadow="shadow" v-for="(item, index) in items.slice(3,5)"
                           :key="index"/>
        </ul>
    </nav>
</template>

<script>
import LegoNavItem from './NavItem';

export default {
    name: 'lego-nav-bar',
    components: { LegoNavItem },

    props: {
        logoText: { type: String, default: 'Xeoscript Technologies' },
        items: { type: Array, required: true },
        shadow: { type: String, default: '5' },
        transparent: { type: Boolean, default: false },
        colored: { type: Boolean, default: false },
        sticky: { type: Boolean, default: false },
        dropDownAnimation: { type: String, default: 'zoom' },
        hoverEffect: { type: String, default: 'basic-1' },
        hasSeparator: { type: Boolean, default: false },
        dropdownRound: { type: [String, Number], default: '' },
        dropDownAnimationMobile: { type: String, default: 'bounce' }
    },
    data () {
        return {
            animate: false,
            showMenu: false
        };
    },

    mounted () {
        const that = this;
        setTimeout(function () {
            that.enableAnimations();
        }, 200);
    },

    computed: {
        className () {
            const names = ['nav-bar'];
            if (this.shadow) {
                names.push(`bs-${this.shadow}`);
            }

            if (this.hoverEffect) {
                names.push(`nav-he-${this.hoverEffect}`);
            }

            if (this.dropDownAnimation) {
                names.push(`nav-dd-${this.dropDownAnimation}`);
                if (this.animate) {
                    names.push('nav-dd-animate');
                }
            }

            if (this.transparent) {
                names.push('nav-transparent');
            }

            if (this.colored) {
                names.push('nav-colored');
            }

            if (this.sticky) {
                names.push('nav-sticky');
            }

            if (this.dropDownAnimationMobile) {
                names.push(`nav-mdd-${this.dropDownAnimationMobile}`);
                if (this.animate) {
                    names.push('nav-mdd-animate');
                }
            }

            return names;
        }
    },

    methods: {
        bubble (name) {
            this.$emit('link-event', name);
        },

        enableAnimations () {
            this.animate = true;
        }
    }
};
</script>

<style scoped>

</style>
