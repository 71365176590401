<template>
    <div class="pos-r" id="top">
        <div class="nav-cont-c">
            <lego-nav-bar-custom drop-down-animation="fade" drop-down-animation-mobile="fade" shadow="0"
                                 class="container d-sm-none"
                                 logo-text="" :items="menu">
                <template #logo>
                    <router-link to="/" class="d-flex">
                        <img src="../assets/img/logo.png" alt="#" class="logo-img py-2">
                    </router-link>
                </template>
            </lego-nav-bar-custom>
            <!--            Navbar Mobile-->
            <custom-mobile-navbar class="d-lg-none"></custom-mobile-navbar>

        </div>

        <div class="mt-nav">
            <transition name="fade" mode="out-in" @after-leave="afterLeave">
                <router-view>

                </router-view>
            </transition>
        </div>

        <TopFooter/>
        <div class="nav-overlay"></div>
    </div>

</template>

<script>
import LegoNavBarCustom from '../components/NavBar';
import getMenu from '../data/menu';
import TopFooter from '../views/components/Footer';
import CustomMobileNavbar from '@components/CustomMobileNavbar';

export default {
    components: {
        CustomMobileNavbar,
        TopFooter,
        LegoNavBarCustom
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    methods: {
        afterLeave () {
            // alert('trigger scroll');
            this.$root.$emit('triggerScroll');
        }
        // linkEvent (name) {
        //     if (name === 'open-submenu') {
        //         alert('abc');
        //     }
        // },

    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.mt-nav {
    padding-top: 84px;
}

@media (max-width: 992px) {
    .mt-nav {
        padding-top: 57px;
    }
}
</style>
