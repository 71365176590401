import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text: 'Our Work',
            link: '/',
            itemsRef: 'our-work-items',
            items: [
                {
                    text: 'Our Focus',
                    link: '/#ourWorks'
                },
                {
                    text: 'Impact Stories',
                    link: '/#impactStories'
                },
                {
                    text: 'Present Work',
                    link: '/#presentWork'
                },
                {
                    text: 'Contact',
                    link: '/contact/#MessageUsForm'
                }
            ]
        },
        {
            text: 'Our History',
            link: '/our-history/',
            itemsRef: 'our-history-items',
            items: [
                {
                    text: 'Esaf Founders',
                    link: '/our-history/#ourFounders'
                },
                {
                    text: 'Esaf History',
                    link: '/our-history/#historyBanner'
                },
                {
                    text: 'Esaf India Awards',
                    link: '/our-history/#AwardsAndRecognition'
                },
                {
                    text: 'Esaf India Partners',
                    link: '/our-history/#PartnerSection'
                }
                // {
                //     text: 'More About our History',
                //     link: '/our-history/#historyBanner'
                // }
            ]
        },
        {
            text: 'About Us',
            link: '/about/',
            itemsRef: 'about-items',
            items: [
                {
                    text: 'Our Vision & Mission',
                    link: '/about/#VisionAndMission'
                },
                {
                    text: 'Our Values',
                    link: '/about/#ourValues'
                },
                {
                    text: 'Our Strategy',
                    link: '/about/#ourStrategy'
                },
                {
                    text: 'Our Leaders',
                    link: '/about/#ourBoard'
                },
                {
                    text: 'Work With Us',
                    link: '/partner-with-us/'
                }
            ]
        },
        {
            text: 'Partner With Us',
            link: '/partner-with-us/',
            itemsRef: 'partner-items',
            class: 'mr-xl-1',
            items: [
                {
                    text: 'Fundraise',
                    link: '/contact/#MessageUsForm'
                },
                {
                    text: 'Brand Partnership',
                    link: '/contact/#MessageUsForm'
                },
                {
                    text: 'Legacy Giving',
                    link: '/contact/#MessageUsForm'
                },
                {
                    text: 'Celebrate your Special Days',
                    link: '/contact/#MessageUsForm'
                },
                {
                    text: 'Give in Someone\'s Honor',
                    link: '/contact/#MessageUsForm'
                }
            ]
        },
        {
            text: 'Donate Now',
            link: '/donate/',
            type: 'button',
            class: 'nav-btn-cont',
            btnAttrs: {
                design: 'basic-a',
                color: 'white',
                class: 'round-nav-btn-r'
            }
        }
    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
