<template>
    <footer class="">
        <div class="py-5 top-footer-cont">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg mb-4">
                        <h2 class="text-head mb-3">Our Work</h2>
                        <ul class="list-unstyled mb-3">
                            <li class="mb-2">
                                <!--                                <router-link v-scroll-to="'#ourWorks'" to="/" class="d-block mb-2">Our Focus-->
                                <!--                                </router-link>-->
                                <a href="/#ourWorks">Our Focus</a>

                            </li>
                            <li class="mb-2">
                                <!--                                <router-link to="/" class="d-block mb-2">Impact Stories</router-link>-->
                                <a href="/#impactStories" class="d-block mb-2">Impact Stories</a>
                            </li>
                            <li class="mb-2">
                                <!--                                <router-link to="/" class="d-block mb-2">Present Work Location</router-link>-->
                                <a href="/#presentWork" class="d-block mb-2">Present Work Location</a>
                            </li>
                            <li class="mb-2">
                                <router-link to="/contact/" class="d-block">Contact Us</router-link>

                            </li>
                        </ul>

                    </div>
                    <div class="col-12 col-md-6 col-lg mb-4">
                        <h3 class="text-head mb-4">Our History</h3>
                        <ul class="list-unstyled mb-3">
                            <li class="mb-2">
                                <a href='/our-history/#ourFounders' class="d-block">Esaf Founders</a>
                            </li>
                            <li class="mb-2">
                                <a href='/our-history/#historyBanner' class="d-block">Esaf History</a>
                            </li>
                            <li class="mb-2">
                                <a href="/our-history/#AwardsAndRecognition" class="d-block">Esaf India Awards</a>
                            </li>
                            <li class="mb-2">
                                <a href="/our-history/#PartnerSection" class="d-block">Esaf India Partners</a>
                            </li>
<!--                            <li class="mb-2">-->
<!--                                <a href="/our-history/#historyBanner" class="d-block">More About our History</a>-->
<!--                            </li>-->
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg mb-3 mb-lg-0">
                        <h3 class="text-head mb-3">About Us</h3>
                        <ul class="list-unstyled">
                            <li class="mb-2">
                                <a href="/about/#VisionAndMission">Our Vision & Mission</a>
                            </li>
                            <li class="mb-2">
                                <a href="/about/#ourValues">Our Values</a>
                            </li>
                            <li class="mb-2">
                                <a href="/about/#ourStrategy">Our Strategy</a>
                            </li>
                            <li class="mb-2">
                                <a href="/about/#ourBoard">Our Leaders</a>
                            </li>
                            <li class="mb-2">
                                <router-link to="/partner-with-us/">Work With Us</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg mb-4">
                        <h3 class="text-head mb-3">Partner With Us</h3>
                        <ul class="list-unstyled">
                            <li class="mb-2">
                                <a href="/contact/#MessageUsForm" >Fundraise</a>
                            </li>
                            <li class="mb-2">
                                <a href="/contact/#MessageUsForm" >Brand Partnership</a>
                            </li>
                            <li class="mb-2">
                                <a href="/contact/#MessageUsForm" >Legacy Giving</a>
                            </li>
                            <li class="mb-2">
                                <a href="/contact/#MessageUsForm" >Celebrate your Special Days</a>
                            </li>
                            <li class="mb-2">
                                <a href="/contact/#MessageUsForm" >Give in Someone's Honor</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg mb-4">
                        <h3 class="text-head mb-3">Donate</h3>
                        <ul class="list-unstyled">
                            <li class="mb-2">
                                <router-link to="/donate/">Donate to General Fund</router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/donate/">Donate to Project Fund</router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/donate/">Adopt a Mobile School</router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/donate/">Adopt a Mobile Clinic</router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/donate/">Adopt a Skill Training Center</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <h3 class="text-head text-center w-100 mb-3">Our Partners</h3>
                </div>
                <div class="row fl-j-c mb-4">
                    <div class="col-12 col-md-3 fl-x fl-j-sa mb-4">
                        <img src="../../assets/img/partners/01.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-12 col-md-3 fl-x fl-j-sa mb-4">
                        <img src="../../assets/img/partners/02.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-12 col-md-3 fl-x fl-j-sa mb-4">
                        <img src="../../assets/img/partners/03.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="row">
                    <!--                    <div class="col-12 col-md-4 fl-y fl-j-s mb-4">-->
                    <!--                        <div>-->
                    <!--                            <h3 class="text-head mb-4">Volunteer With Us</h3>-->
                    <!--                            <p>-->
                    <!--                                Introduce us to your community/ invite us to your organization to share our vision-->
                    <!--                            </p>-->
                    <!--                        </div>-->
                    <!--                        <router-link to="/volunteer/"-->
                    <!--                                     class="lego-btn w-60 btn-basic-b btn-color-white round-1 bs-1 bs-1-h line-h-unset">-->
                    <!--                            Volunteer Now-->
                    <!--                        </router-link>-->
                    <!--                        &lt;!&ndash;                        <btn design="basic-b" color="white" class="w-60" text="Volunteer Now"></btn>&ndash;&gt;-->
                    <!--                    </div>-->
                    <div class="col-12 col-md-4 fl-y fl-j-s mb-4">
                        <div class="fl-y fl-j-e">
                            <h3 class="text-head mb-4">Find Us At</h3>
                            <p>
                                PO Box 68152 Crowfoot PO <br> Calgary AB Canada T3G 3N8
                            </p>
<!--                            <p>-->
<!--                                11680 Sarcee Trail Nw Calgary <br>-->
<!--                                AB, T3R 0A1, Canada-->
<!--                                &lt;!&ndash;                                <br><br>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                Charity Registration No. 795217942 RR0001&ndash;&gt;-->
<!--                            </p>-->
                        </div>
                    </div>
                    <div class="col-12 col-md-4 fl-y fl-j-s mb-4">
                        <h3 class="text-head mb-4">Charity Registration</h3>
                        <p>No. 795217942 RR0001</p>
                    </div>

                    <div class="col-12 col-md-4 fl-y fl-j-s mb-4">
                        <h3 class="text-head mb-4">Follow Us On</h3>
                        <ul class="social-links fl-x fl-a-c">
                            <li class="mr-2">
<!--                                <a href="https://www.facebook.com/esafsociety/?modal=media_composer&ref=page_homepage_panel"-->
<!--                                   target="_blank"><i class="fa fa-facebook fa-2x p-2"></i>-->
<!--                                </a>                                -->
                                <a href="https://m.facebook.com/ESAF-Global-Canada-104896482139680/"
                                   target="_blank"><i class="fa fa-facebook fa-2x p-2"></i></a>
                            </li>
                            <li class="mr-2">
<!--                                <a href="https://www.instagram.com/esafsociety/" target="_blank"><i-->
<!--                                    class="fa fa-instagram fa-2x p-2"></i></a>                                -->
                                <a href="https://www.instagram.com/esafglobal/" target="_blank"><i
                                    class="fa fa-instagram fa-2x p-2"></i></a>
                            </li>
                            <li class="mr-2">
                                <a href="mailto:info@esafglobal.ca" target="_blank"><i
                                    class="fa fa-envelope fa-2x p-2"></i></a>
                            </li>
                            <li class="mr-2">
                                <a href="https://www.linkedin.com/company/esaf-global-canada/?viewAsMember=true"
                                   target="_blank"><i class="fa fa-linkedin fa-2x p-2"></i></a>
                            </li>
                            <li>
                                <!--                                <btn design="basic-b" color="white" size="md" text="Contact Us"></btn>-->
                                <a href="/contact/#MessageUsForm"
                                             class="lego-btn btn-basic-b btn-color-white round-1 bs-1 bs-1-h line-h-unset">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-dark p-3 bottom-footer-cont">
            <div class="container">
                <div class="row">
                    <div class="col bottom-footer">
                        <p class="mb-lg-0 text-center w-100">{{ new Date().getFullYear() }} &copy; Copyright ESAF
                            group
                            Alright Reserved
                            <span class="d-none d-md-inline-block">|</span> Designed by
                            <a href="https://www.xeoscript.com/" target="_blank" class="text-white">Xeoscript
                                Technologies</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Top-Footer',
    methods: {
        routingPrevDefault (e) {
            console.log('routingPreventDefault', e.target.href);
            this.$router.push(e.target.href);
        }
    }
};
</script>

<style scoped lang="scss">

.social-links {
    display: flex;
    //width: 80%;
    justify-content: flex-start;
    list-style: none;

    li {
        :not(:last-child) {
            transition: all ease-in-out 300ms;
            border: 2px solid #ddd;
            opacity: 0.6;
            border-radius: 0.5rem;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.6rem;
                color: white;
            }

            &:hover {
                //transform: scale(1.03);

                i {
                    color: #9e9e9e;
                }
            }
        }
    }
}

.top-footer-cont {
    background-color: #262626;
    color: #888888;

    .text-head {
        font-weight: bold !important;
        font-size: 1.2rem;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .text-details {
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid #333333;
            transition: 0.3s;

            &:hover {
                color: #cccccc;

                &:after {
                    color: #cccccc;
                }
            }

            &:after {
                font-family: "FontAwesome";
                content: "\f105";
                position: absolute;
                top: 10px;
                right: 0;
                font-size: 14px;
                color: #999999;
            }
        }
    }

}

a {
    text-decoration: none;
}

a:hover {
    color: #cccccc;
}

.bottom-footer-cont {
    background-color: #1b1b1b;
    color: #777777;

    .bottom-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .social-icons {
            li {
                display: inline-block;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .bottom-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.w-60 {
    width: 60% !important;
}

.text-white {
    &:hover {
        opacity: 0.6 !important;
    }
}
</style>
